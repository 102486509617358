import $ from 'jquery';
import 'jquery.scrollto';

window.$ = $;
window.jQuery = $;


(() => {
  const $message = $('#message');
  const $window = $(window);
  const top = 0;
  // var top = $(document.body).children(0).position().top;
  // var top = $(window).height();

  let scrollTimer;
  let displayed = false;

  /* react to scroll event on window */
  $window.scroll(() => {
    window.clearTimeout(scrollTimer);
    scrollTimer = window.setTimeout(() => { // use a timer for performance
      if ($window.scrollTop() <= top) {
        // hide if at the top of the page
        displayed = false;
        $message.fadeOut(500);
      } else if (displayed === false) {
        // show if scrolling down
        displayed = true;
        $message.stop(true, true).fadeIn(500);
      }
    }, 100);
  });

  $('#top-link').click((e) => {
    e.preventDefault();
    $.scrollTo(0, 300);
  });
})();
